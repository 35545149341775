<template>
    <div :style="{ 'height': height + 'px', 'width': width + 'px' }">
        <img :src="load ? path : ''">
    </div>
</template>

<script>
export default {
    props: ['path', 'height', 'size'],
    data: function() {
        return {
            load: false,
            preload: 2,
        }
    },
    computed: {
        width: (vm) => Math.floor(vm.height * (vm.size.width / vm.size.height)),
    },
    methods: {
        isUnder(x, y) {
            var rect = this.$el.getBoundingClientRect();
            return (
                rect.top < y &&
                rect.left < x &&
                rect.bottom > y &&
                rect.right > x
            );
            
        },
        loadIfVisible() {
            var rect = this.$el.getBoundingClientRect();
            if (
                rect.top < window.innerHeight &&
                rect.left < (1 + this.preload) * window.innerWidth &&
                rect.bottom > 0 &&
                rect.right > -1 * this.preload * window.innerWidth
            ) this.load = true;
        },
        scrollTo() {
            this.$el.scrollIntoView({inline: 'end'});
        },
    },
}
</script>
