<template>
    <div id="reader" class="flex flex-col h-full">
        <header class="flex flex-row flex-none bg-gray-700 text-white p-1">
            <a :class="buttonClasses" :href="show_path" class="flex content-center cursor-pointer h-8 w-8">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-undo" class="object-contain stroke-current fill-current m-auto" width="24" height="24" viewBox="0 0 24 24"><path d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" /></svg>
            </a>

            <div class="flex-grow"></div>

            <button :class="buttonClasses" class="h-8 w-8" @click="currentPage = images.length; updatePosition()" :disabled="currentPage == images.length">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-page-first" class="object-contain stroke-current fill-current m-auto" width="24" height="24" viewBox="0 0 24 24"><path d="M18.41,16.59L13.82,12L18.41,7.41L17,6L11,12L17,18L18.41,16.59M6,6H8V18H6V6Z" /></svg>
            </button>
            <button :class="buttonClasses" class="h-8 w-8" @click="currentPage++; updatePosition()" :disabled="currentPage == images.length">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-chevron-left" class="object-contain stroke-current fill-current m-auto" width="24" height="24" viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" /></svg>
            </button>

            <select v-model.lazy="currentPage" @change="updatePosition" :class="buttonClasses" class="text-center p-0 h-8 w-16">
                <option disabled value="">{{ 'Select page' }}</option>
                <option v-for="(image, index) in images" :key="index">{{ index + 1 }}</option>
            </select>
            <p class="my-auto mx-1">/ {{ images.length }}</p>

            <button :class="buttonClasses" class="h-8 w-8" @click="currentPage--; updatePosition()" :disabled="currentPage == 1">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-chevron-right" class="object-contain stroke-current fill-current m-auto" width="24" height="24" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" /></svg>
            </button>
            <button :class="buttonClasses" class="h-8 w-8" @click="currentPage = 1; updatePosition()" :disabled="currentPage == 1">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-page-last" class="object-contain stroke-current fill-current m-auto" width="24" height="24" viewBox="0 0 24 24"><path d="M5.59,7.41L10.18,12L5.59,16.59L7,18L13,12L7,6L5.59,7.41M16,6H18V18H16V6Z" /></svg>
            </button>

            <div class="flex-grow"></div>
        </header>
        <main class="flex-auto overflow-auto bg-black">
            <div ref="imgContainer" v-on:scroll="updatePage" class="flex flex-row-reverse overflow-y-hidden h-full">
                <div class="flex-grow"></div>
                <reader-page v-for="(image, index) in images" 
                    :key="index"
                    :path="path + '/' + image"
                    :height="height"
                    :size="sizes[index]"
                    :style="{ 'margin-left': padding + 'px', 'margin-right': padding + 'px' }"
                    class="flex-none my-auto"
                ></reader-page>
                <div class="flex-grow"></div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    props: ['page', 'path', 'images', 'sizes', 'show_path'],
    data: function() {
        return {
            title: document.title,
            currentPage: null,
            aspectRatio: 1360/1920,
            height: 0,
            padding: 4,
            buttonClasses: 'hide-buttons rounded-full disabled:opacity-50 bg-gray-600 hover:opacity-80 border border-gray-500 mx-1',
        }
    },
    methods: {
        updateHeight: window.throttle(function() {
            const ic = this.$refs.imgContainer;
            this.height = Math.floor(Math.min(ic.clientHeight, (ic.clientWidth - 2 * this.padding) / this.aspectRatio));
        }, 100),
        updateVisible: window.throttle(function() {
            this.$children.forEach((c) => { c.loadIfVisible(); });
        }, 100),
        updatePosition() {
            if (this.currentPage < 0) this.currentPage = 1;
            if (this.currentPage > this.images.length) this.currentPage = this.images.length;

            this.$children[this.currentPage-1].scrollTo();
        },
        updatePage: window.throttle(function() {
            this.updateVisible();

            var ic = this.$refs.imgContainer;

            if (ic.scrollWidth - ic.clientWidth + ic.scrollLeft === 0) {
                this.currentPage = this.images.length;
                return;
            }

            var x = window.innerWidth - this.height * this.aspectRatio / 2;
            var y = window.innerHeight - this.height / 2;

            this.$children.forEach((c, i) => { 
                if (c.isUnder(x, y)) {
                    var cp = i + 1;
                    if (cp != this.currentPage) this.currentPage = cp;
                    return;
                }
            });
        }, 100),
    },
    watch: {
        currentPage: function() {
            history.replaceState(null, '', this.currentPage);
            document.title = '[' + this.currentPage + '/' + this.images.length + '] ' + this.title;
        },
    },
    created() {
        window.addEventListener("resize", this.updateHeight);
    },
    mounted() {
        this.updateHeight();
    },
    updated() {
        if (this.currentPage === null) {
            this.currentPage = this.page;
            this.updatePosition();
            this.updateVisible();
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateHeight);
    },
}
</script>
