/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('alpinejs');
window.Vue = require('vue').default;

/** 
 * Throttle function callbacks.
 * 
 * @param   {Function}      callback    The function to be throttled.
 * @param   {Number}        limit       The minimum interval of time (ms) needed between calls
 * @returns {Function}
*/
window.throttle = function(callback, limit) {
    var lastTime = 0;
    var timeoutId = null;
    return function () {
        var now = Date.now();
        if (lastTime + limit < now) {
            callback.call(this, arguments);
            lastTime = now;
        }
        else {
            if (timeoutId !== null) clearTimeout(timeoutId);
            timeoutId = setTimeout(callback.bind(this, arguments), lastTime + limit - now);
        }
    }
}

/** 
 * Analog to PHP's "rawUrlEncode()".
 * 
 * @param   {String}        str     The string to be encoded.
 * @returns {String}
*/
window.rawUrlEncode = function(str) {
    return encodeURIComponent(str)
        .replace(/!/g, '%21')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/\*/g, '%2A')
}

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('../views/components', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('../views/components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
